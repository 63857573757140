// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import $ from "jquery";
global.$ = $;
global.jQuery = $;

require("@rails/ujs").start()
// require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("../src/site/jquery-ui")
import WOW from 'vendor/wow'
require("bootstrap/js/dist/scrollspy")
require("bootstrap")
require("../src/site/bootstrap-select.min")
require("../src/site/jquery.bootstrap-touchspin")
require("../src/site/popper.min")
require("../src/site/magnific-popup")
require("../src/site/waypoints-min")
require("../src/site/counterup.min")
require("../src/site/imagesloaded")
require("../src/site/masonry.filter")
require("slimscroll")
import 'owl.carousel';
require("../src/site/lightgallery-all")
require("../src/site/custom")
require("../src/site/dz.carousel")
require("../src/site/jquery.countdown")
require("counter")
require("../src/site/dz.ajax")
require("../src/site/rangeslider")
require("../src/site/jquery.lazy.min")
// require("vendor/rev.slider.js")
require('../src/site/revolution/jquery.themepunch.tools.min')
require("../src/site/revolution/jquery.themepunch.revolution.min")
require("../src/site/revolution/extensions/revolution.extension.actions.min")
require("../src/site/revolution/extensions/revolution.extension.carousel.min")
require("../src/site/revolution/extensions/revolution.extension.kenburn.min")
require("../src/site/revolution/extensions/revolution.extension.layeranimation.min")
require("../src/site/revolution/extensions/revolution.extension.navigation.min")
require("../src/site/revolution/extensions/revolution.extension.parallax.min")
require("../src/site/revolution/extensions/revolution.extension.slideanims.min")
require("../src/site/revolution/extensions/revolution.extension.video.min")
require("../src/site/slick.js")

import IMask from 'imask';


$(document).ready(function() {

  var dynamicMask = IMask(
    document.getElementById('feedback_email_1'), {
      mask: [{
          mask: '+{7}(000)000-00-00'
        },
        {
          mask: /^\S*@?\S*$/
        }
      ]
    }
  );

  var dynamicMask1 = IMask(
    document.getElementById('feedback_email_0'), {
      mask: [{
          mask: '+{7}(000)000-00-00'
        },
        {
          mask: /^\S*@?\S*$/
        }
      ]
    }
  );

  var sync1 = $('#sync1');
  var sync2 = $('#sync2');
  var slidesPerPage = 4; //globaly define number of elements per page
  var syncedSecondary = true;

  sync1.owlCarousel({
    items: 1,
    nav: true,
    autoplaySpeed: 3000,
    // navSpeed: 3000,
    // paginationSpeed: 3000,
    // slideSpeed: 3000,
    // smartSpeed: 3000,
    autoplay: false,
    dots: false,
    loop: false,
    responsiveRefreshRate: 200,
    navText: ['<i class="la la-angle-left"></i>', '<i class="la la-angle-right"></i>'],
    responsive: {
      0: {
        nav: false
      },
      768: {
        nav: true
      }
    }
  }).on('changed.owl.carousel', syncPosition);

  sync2.on('initialized.owl.carousel', function() {
    sync2.find('.owl-item').eq(0).addClass('current');
  }).owlCarousel({
    items: slidesPerPage,
    dots: false,
    nav: false,
    margin: 20,
    autoplaySpeed: 3000,
    // navSpeed: 3000,
    // paginationSpeed: 3000,
    // slideSpeed: 3000,
    // smartSpeed: 3000,
    autoplay: false,
    slideBy: slidesPerPage, //alternatively you can slide by 1, this way the active slide will stick to the first item in the second carousel
    responsiveRefreshRate: 100,
    responsive: {
      0: {
        items: 2
      },
      480: {
        items: 2
      },
      768: {
        items: 3,
      },
      1024: {
        items: 4
      },
      1400: {
        items: 4
      }
    }
  }).on('changed.owl.carousel', syncPosition2);

  function syncPosition(el) {
    //if you set loop to false, you have to restore this next line
    var current = el.item.index;

    //if you disable loop you have to comment this block
    /*var count = el.item.count - 1;
    var current = Math.round(el.item.index - (el.item.count / 2) - .5);

    if (current < 0) {
        current = count;
    }
    if (current > count) {
        current = 0;
    }*/

    //end block

    // console.log(current);

    sync2
      .find('.owl-item')
      .removeClass('current')
      .eq(current)
      .addClass('current');
    var onscreen = sync2.find('.owl-item.active').length - 1;
    var start = sync2.find('.owl-item.active').first().index();
    var end = sync2.find('.owl-item.active').last().index();

    /*if (current > end) {
        sync2.data('owl.carousel').to(current, 100, true);
    }
    if (current < start) {
        sync2.data('owl.carousel').to(current - onscreen, 100, true);
    }*/
  }

  function syncPosition2(el) {
    if (syncedSecondary) {
      var number = el.item.index;
      sync1.data('owl.carousel').to(number, 100, true);
    }
  }

  sync2.on('click', '.owl-item', function(e) {
    e.preventDefault();
    var number = $(this).index();
    //sync1.data('owl.carousel').to(number, 300, true);

    sync1.data('owl.carousel').to(number, 300, true);

  });

  $('.slick-carousel').slick({
    arrows: true,
    prevArrow: '<button type="button" class="slick-prev"><i class="la la-long-arrow-left"></i></button>',
    nextArrow: '<button type="button" class="slick-next"><i class="la la-long-arrow-right"></i></button>'
  })

  require('startbootstrap-scrolling-nav/js/scrolling-nav')
});

var dzrevapi;
var dzQuery = jQuery;

function dz_rev_slider_1() {
  dzrevapi = dzQuery("#welcome").show().revolution({
    sliderType: "standard",
    sliderLayout: "fullscreen",
    dottedOverlay: "none",
    delay: 9000,
    particles: {
      startSlide: "first",
      endSlide: "last",
      zIndex: "1",
      particles: {
        number: {
          value: 25
        },
        color: {
          value: "#ffffff"
        },
        shape: {
          type: "circle",
          stroke: {
            width: 0,
            color: "#ffffff",
            opacity: 1
          },
          image: {
            src: ""
          }
        },
        opacity: {
          value: 1,
          random: true,
          min: 0.25,
          anim: {
            enable: true,
            speed: 3,
            opacity_min: 0,
            sync: false
          }
        },
        size: {
          value: 2,
          random: true,
          min: 0.5,
          anim: {
            enable: true,
            speed: 5,
            size_min: 1,
            sync: false
          }
        },
        line_linked: {
          enable: false,
          distance: 150,
          color: "#ffffff",
          opacity: 0.4,
          width: 1
        },
        move: {
          enable: true,
          speed: 2,
          direction: "none",
          random: true,
          min_speed: 1,
          straight: false,
          out_mode: "out"
        }
      },
      interactivity: {
        events: {
          onhover: {
            enable: false,
            mode: "bubble"
          },
          onclick: {
            enable: false,
            mode: "repulse"
          }
        },
        modes: {
          grab: {
            distance: 400,
            line_linked: {
              opacity: 0.5
            }
          },
          bubble: {
            distance: 400,
            size: 40,
            opacity: 0.5
          },
          repulse: {
            distance: 200
          }
        }
      }
    },
    revealer: {
      direction: "open_horizontal",
      color: "#ffffff",
      duration: "1500",
      delay: "0",
      easing: "Power2.easeInOut",
      overlay_enabled: true,
      overlay_color: "#000000",
      overlay_duration: "1500",
      overlay_delay: "0",
      overlay_easing: "Power2.easeInOut",
      spinner: "1",
      spinnerColor: "#006dd2",
    },
    navigation: {
      keyboardNavigation: "off",
      keyboard_direction: "horizontal",
      mouseScrollNavigation: "off",
      mouseScrollReverse: "default",
      onHoverStop: "off",
      arrows: {
        style: "uranus",
        enable: true,
        hide_onmobile: false,
        hide_onleave: false,
        tmp: '',
        left: {
          h_align: "left",
          v_align: "center",
          h_offset: 10,
          v_offset: 0
        },
        right: {
          h_align: "right",
          v_align: "center",
          h_offset: 10,
          v_offset: 0
        }
      }
    },
    viewPort: {
      enable: true,
      outof: "wait",
      visible_area: "80%",
      presize: true
    },
    responsiveLevels: [1240, 1025, 778, 480],
    visibilityLevels: [1240, 1025, 778, 480],
    gridwidth: [1240, 1025, 778, 480],
    gridheight: [970, 970, 960, 720],
    lazyType: "single",
    parallax: {
      type: "scroll",
      origo: "slidercenter",
      speed: 400,
      speedbg: 0,
      speedls: 0,
      levels: [5, 10, 15, 20, 25, 30, 35, 40, 45, 46, 47, 48, 49, 50, 51, 55],
    },
    shadow: 0,
    spinner: "spinner2",
    stopLoop: "on",
    stopAfterLoops: 0,
    stopAtSlide: 1,
    shuffle: "off",
    autoHeight: "off",
    fullScreenAutoWidth: "off",
    fullScreenAlignForce: "off",
    fullScreenOffsetContainer: ".header",
    fullScreenOffset: "",
    disableProgressBar: "on",
    hideThumbsOnMobile: "off",
    hideSliderAtLimit: 0,
    hideCaptionAtLimit: 0,
    hideAllCaptionAtLilmit: 0,
    debugMode: false,
    fallbacks: {
      simplifyAll: "off",
      nextSlideOnWindowFocus: "off",
      disableFocusListener: false,
    }
  });

  //	}
}

function dz_rev_slider_2() {
  if (dzQuery("#rev_slider_1164_1").revolution == undefined) {
    revslider_showDoubleJqueryError("#rev_slider_1164_1");
  } else {
    dzrevapi = dzQuery("#rev_slider_1164_1").show().revolution({
      sliderType: "standard",
      jsFileLocation: "plugins/revolution/revolution/js/",
      sliderLayout: "fullscreen",
      dottedOverlay: "none",
      delay: 9000,
      navigation: {
        keyboardNavigation: "off",
        keyboard_direction: "horizontal",
        mouseScrollNavigation: "off",
        mouseScrollReverse: "default",
        onHoverStop: "off",
        touch: {
          touchenabled: "on",
          swipe_threshold: 75,
          swipe_min_touches: 1,
          swipe_direction: "horizontal",
          drag_block_vertical: false
        }

      },
      responsiveLevels: [1240, 1025, 778, 480],
      visibilityLevels: [1240, 1025, 778, 480],
      gridwidth: [1240, 1025, 778, 480],
      gridheight: [970, 970, 960, 720],
      lazyType: "single",
      parallax: {
        type: "scroll",
        origo: "slidercenter",
        speed: 400,
        levels: [5, 10, 15, 20, 25, 30, 35, 40, 45, 46, 47, 48, 49, 50, 51, 55],
        type: "scroll",
      },
      shadow: 0,
      spinner: "off",
      autoHeight: "off",
      fullScreenAutoWidth: "off",
      fullScreenAlignForce: "off",
      fullScreenOffsetContainer: ".site-header",
      disableProgressBar: "on",
      hideThumbsOnMobile: "off",
      hideSliderAtLimit: 0,
      hideCaptionAtLimit: 0,
      hideAllCaptionAtLilmit: 0,
      debugMode: false,
      fallbacks: {
        simplifyAll: "off",
        nextSlideOnWindowFocus: "off",
        disableFocusListener: false,
      }
    });
  }
}

function dz_rev_slider_3() {
  if (dzQuery("#rev_slider_14_1").revolution == undefined) {
    revslider_showDoubleJqueryError("#rev_slider_14_1");
  } else {
    dzrevapi = dzQuery("#rev_slider_14_1").show().revolution({
      sliderType: "hero",
      jsFileLocation: "plugins/revolution/revolution/js/",
      sliderLayout: "fullscreen",
      dottedOverlay: "none",
      delay: 9000,
      particles: {
        startSlide: "first",
        endSlide: "last",
        zIndex: "6",
        particles: {
          number: {
            value: 100
          },
          color: {
            value: "#ffffff"
          },
          shape: {
            type: "circle",
            stroke: {
              width: 0,
              color: "#ffffff",
              opacity: 1
            },
            image: {
              src: ""
            }
          },
          opacity: {
            value: 1,
            random: true,
            min: 0.25,
            anim: {
              enable: false,
              speed: 3,
              opacity_min: 0,
              sync: false
            }
          },
          size: {
            value: 3,
            random: true,
            min: 0.5,
            anim: {
              enable: false,
              speed: 40,
              size_min: 1,
              sync: false
            }
          },
          line_linked: {
            enable: false,
            distance: 150,
            color: "#ffffff",
            opacity: 0.4,
            width: 1
          },
          move: {
            enable: true,
            speed: 1,
            direction: "top",
            random: true,
            min_speed: 1,
            straight: false,
            out_mode: "out"
          }
        },
        interactivity: {
          events: {
            onhover: {
              enable: true,
              mode: "bubble"
            },
            onclick: {
              enable: false,
              mode: "repulse"
            }
          },
          modes: {
            grab: {
              distance: 400,
              line_linked: {
                opacity: 0.5
              }
            },
            bubble: {
              distance: 400,
              size: 0,
              opacity: 0.01
            },
            repulse: {
              distance: 200
            }
          }
        }
      },
      navigation: {},
      responsiveLevels: [1240, 1024, 778, 480],
      visibilityLevels: [1240, 1024, 778, 480],
      gridwidth: [1240, 1024, 778, 480],
      gridheight: [868, 768, 960, 720],
      lazyType: "none",
      parallax: {
        type: "mouse",
        origo: "slidercenter",
        speed: 400,
        levels: [1, 2, 3, 4, 5, 10, 15, 20, 25, 46, 47, 48, 49, 50, 51, 55],
      },
      shadow: 0,
      spinner: "off",
      autoHeight: "off",
      fullScreenAutoWidth: "off",
      fullScreenAlignForce: "off",
      fullScreenOffsetContainer: "",
      fullScreenOffset: "",
      disableProgressBar: "on",
      hideThumbsOnMobile: "off",
      hideSliderAtLimit: 0,
      hideCaptionAtLimit: 0,
      hideAllCaptionAtLilmit: 0,
      debugMode: false,
      fallbacks: {
        simplifyAll: "off",
        disableFocusListener: false,
      }
    });
  }
}

function dz_rev_slider_4() {
  if (dzQuery("#rev_slider_1077_1").revolution == undefined) {
    revslider_showDoubleJqueryError("#rev_slider_1077_1");
  } else {
    dzrevapi = dzQuery("#rev_slider_1077_1").show().revolution({
      sliderType: "standard",
      jsFileLocation: "plugins/revolution/revolution/js/",
      sliderLayout: "fullscreen",
      dottedOverlay: "none",
      delay: 9000,
      navigation: {
        keyboardNavigation: "off",
        keyboard_direction: "horizontal",
        mouseScrollNavigation: "off",
        mouseScrollReverse: "default",
        onHoverStop: "off",
        touch: {
          touchenabled: "on",
          swipe_threshold: 75,
          swipe_min_touches: 1,
          swipe_direction: "horizontal",
          drag_block_vertical: false
        },
        bullets: {
          enable: true,
          hide_onmobile: true,
          hide_under: 960,
          style: "zeus",
          hide_onleave: false,
          direction: "horizontal",
          h_align: "right",
          v_align: "bottom",
          h_offset: 80,
          v_offset: 50,
          space: 5,
          tmp: '<span class="tp-bullet-image"></span><span class="tp-bullet-imageoverlay"></span><span class="tp-bullet-title">{{title}}</span>'
        }
      },
      responsiveLevels: [1240, 1024, 778, 480],
      visibilityLevels: [1240, 1024, 778, 480],
      gridwidth: [1240, 1024, 778, 480],
      gridheight: [868, 768, 960, 720],
      lazyType: "none",
      parallax: {
        type: "mouse",
        origo: "slidercenter",
        speed: 2000,
        levels: [2, 3, 4, 5, 6, 7, 12, 16, 10, 50, 46, 47, 48, 49, 50, 55],
        type: "mouse",
        disable_onmobile: "on"
      },
      shadow: 0,
      spinner: "off",
      stopLoop: "on",
      stopAfterLoops: 0,
      stopAtSlide: 1,
      shuffle: "off",
      autoHeight: "off",
      fullScreenAutoWidth: "off",
      fullScreenAlignForce: "off",
      fullScreenOffsetContainer: ".site-header",
      fullScreenOffset: "0px",
      disableProgressBar: "on",
      hideThumbsOnMobile: "off",
      hideSliderAtLimit: 0,
      hideCaptionAtLimit: 0,
      hideAllCaptionAtLilmit: 0,
      debugMode: false,
      fallbacks: {
        simplifyAll: "off",
        nextSlideOnWindowFocus: "off",
        disableFocusListener: false,
      }
    });
    var newCall = new Object(),
      cslide;

    newCall.callback = function() {
      var proc = revapi1077.revgetparallaxproc(),
        fade = 1 + proc,
        scale = 1 + (Math.abs(proc) / 10);

      punchgs.TweenLite.set(revapi1077.find('.slotholder, .rs-background-video-layer'), {
        opacity: fade,
        scale: scale
      });
    }
    newCall.inmodule = "parallax";
    newCall.atposition = "start";

    /*revapi1077.bind("revolution.slide.onloaded",function (e) {
    revapi1077.revaddcallback(newCall);
    });*/
  }
}

// function dz_rev_slider_5(){
// 	if(dzQuery("#rev_slider_1175_1").revolution == undefined){
// 		revslider_showDoubleJqueryError("#rev_slider_1175_1");
// 	}else{
// 		dzrevapi = dzQuery("#rev_slider_1175_1").show().revolution({
// 			sliderType:"standard",
// 			jsFileLocation:"plugins/revolution/revolution/js/",
// 			sliderLayout:"fullwidth",
// 			dottedOverlay:"none",
// 			delay:9000,
// 			navigation: {
// 				keyboardNavigation:"off",
// 				keyboard_direction: "horizontal",
// 				mouseScrollNavigation:"off",
// 				mouseScrollReverse:"default",
// 				onHoverStop:"off",
// 				touch:{
// 					touchenabled:"on",
// 					swipe_threshold: 75,
// 					swipe_min_touches: 1,
// 					swipe_direction: "horizontal",
// 					drag_block_vertical: false
// 				}
// 				,
// 				arrows: {
// 					style:"uranus",
// 					enable:true,
// 					hide_onmobile:true,
// 					hide_under:768,
// 					hide_onleave:false,
// 					tmp:'',
// 					left: {
// 						h_align:"left",
// 						v_align:"center",
// 						h_offset:20,
// 						v_offset:0
// 					},
// 					right: {
// 						h_align:"right",
// 						v_align:"center",
// 						h_offset:20,
// 						v_offset:0
// 					}
// 				}
// 			},
// 			responsiveLevels:[1240,1024,778,480],
// 			visibilityLevels:[1240,1024,778,480],
// 			gridwidth:[1240,1024,778,480],
// 			gridheight:[650,650,600,500],
// 			lazyType:"smart",
// 			parallax: {
// 				type:"scroll",
// 				origo:"slidercenter",
// 				speed:400,
// 				levels:[5,10,15,20,25,30,35,40,45,46,47,48,49,50,51,55],
// 				type:"scroll",
// 			},
// 			shadow:0,
// 			spinner:"off",
// 			stopLoop:"on",
// 			stopAfterLoops:0,
// 			stopAtSlide:1,
// 			shuffle:"off",
// 			autoHeight:"off",
// 			fullScreenAutoWidth:"off",
// 			fullScreenAlignForce:"off",
// 			fullScreenOffsetContainer: "",
// 			fullScreenOffset: "70",
// 			disableProgressBar:"on",
// 			hideThumbsOnMobile:"off",
// 			hideSliderAtLimit:0,
// 			hideCaptionAtLimit:0,
// 			hideAllCaptionAtLilmit:0,
// 			debugMode:false,
// 			fallbacks: {
// 				simplifyAll:"off",
// 				nextSlideOnWindowFocus:"off",
// 				disableFocusListener:false,
// 			}
// 		});
// 	}
// }

function dz_rev_slider_6() {
  if (dzQuery("#rev_slider_1077_1").revolution == undefined) {
    revslider_showDoubleJqueryError("#rev_slider_1077_1");
  } else {
    dzrevapi = dzQuery("#rev_slider_1077_1").show().revolution({
      sliderType: "standard",
      jsFileLocation: "plugins/revolution/revolution/js/",
      sliderLayout: "fullscreen",
      dottedOverlay: "none",
      delay: 9000,
      navigation: {
        keyboardNavigation: "off",
        keyboard_direction: "horizontal",
        mouseScrollNavigation: "off",
        mouseScrollReverse: "default",
        onHoverStop: "off",
        touch: {
          touchenabled: "on",
          swipe_threshold: 75,
          swipe_min_touches: 1,
          swipe_direction: "horizontal",
          drag_block_vertical: false
        },
        bullets: {
          enable: true,
          hide_onmobile: true,
          hide_under: 960,
          style: "zeus",
          hide_onleave: false,
          direction: "horizontal",
          h_align: "right",
          v_align: "bottom",
          h_offset: 80,
          v_offset: 50,
          space: 5,
          tmp: '<span class="tp-bullet-image"></span><span class="tp-bullet-imageoverlay"></span><span class="tp-bullet-title">{{title}}</span>'
        }
      },
      responsiveLevels: [1240, 1024, 778, 480],
      visibilityLevels: [1240, 1024, 778, 480],
      gridwidth: [1240, 1024, 778, 480],
      gridheight: [868, 768, 960, 720],
      lazyType: "none",
      parallax: {
        type: "mouse",
        origo: "slidercenter",
        speed: 2000,
        levels: [2, 3, 4, 5, 6, 7, 12, 16, 10, 50, 46, 47, 48, 49, 50, 55],
        type: "mouse",
        disable_onmobile: "on"
      },
      shadow: 0,
      spinner: "off",
      stopLoop: "on",
      stopAfterLoops: 0,
      stopAtSlide: 1,
      shuffle: "off",
      autoHeight: "off",
      fullScreenAutoWidth: "off",
      fullScreenAlignForce: "off",
      fullScreenOffsetContainer: "",
      fullScreenOffset: "0px",
      disableProgressBar: "on",
      hideThumbsOnMobile: "off",
      hideSliderAtLimit: 0,
      hideCaptionAtLimit: 0,
      hideAllCaptionAtLilmit: 0,
      debugMode: false,
      fallbacks: {
        simplifyAll: "off",
        nextSlideOnWindowFocus: "off",
        disableFocusListener: false,
      }
    });
    var newCall = new Object(),
      cslide;

    newCall.callback = function() {
      var proc = revapi1077.revgetparallaxproc(),
        fade = 1 + proc,
        scale = 1 + (Math.abs(proc) / 10);

      punchgs.TweenLite.set(revapi1077.find('.slotholder, .rs-background-video-layer'), {
        opacity: fade,
        scale: scale
      });
    }
    newCall.inmodule = "parallax";
    newCall.atposition = "start";

    revapi1077.bind("revolution.slide.onloaded", function(e) {
      revapi1077.revaddcallback(newCall);
    });
  }
}

function dz_rev_slider_7() {
  if (dzQuery("#rev_slider_one").revolution == undefined) {
    revslider_showDoubleJqueryError("#rev_slider_one");
  } else {
    dzrevapi = dzQuery("#rev_slider_one").show().revolution({
      sliderType: "standard",
      jsFileLocation: "plugins/revolution/revolution/js/",
      sliderLayout: "fullwidth",
      dottedOverlay: "none",
      delay: 5000,
      navigation: {
        keyboardNavigation: "off",
        keyboard_direction: "horizontal",
        mouseScrollNavigation: "off",
        mouseScrollReverse: "default",
        onHoverStop: "off",
        touch: {
          touchenabled: "on",
          touchOnDesktop: "off",
          swipe_threshold: 75,
          swipe_min_touches: 1,
          swipe_direction: "horizontal",
          drag_block_vertical: false
        },
        arrows: {
          style: "uranus",
          enable: true,
          hide_onmobile: true,
          hide_under: 600,
          hide_onleave: false,
          tmp: '',
          left: {
            h_align: "left",
            v_align: "center",
            h_offset: 0,
            v_offset: 0
          },
          right: {
            h_align: "right",
            v_align: "center",
            h_offset: 0,
            v_offset: 0
          }
        }

      },
      responsiveLevels: [1200, 1040, 778, 480],
      visibilityLevels: [1200, 1040, 778, 480],
      gridwidth: [1200, 1040, 778, 480],
      gridheight: [750, 600, 500, 450],
      lazyType: "none",
      parallax: {
        type: "scroll",
        origo: "enterpoint",
        speed: 400,
        levels: [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 46, 47, 48, 49, 50, 55],
        type: "scroll",
      },
      shadow: 0,
      spinner: "off",
      stopLoop: "off",
      stopAfterLoops: -1,
      stopAtSlide: -1,
      shuffle: "off",
      autoHeight: "off",
      hideThumbsOnMobile: "off",
      hideSliderAtLimit: 0,
      hideCaptionAtLimit: 0,
      hideAllCaptionAtLilmit: 0,
      debugMode: false,
      fallbacks: {
        simplifyAll: "off",
        nextSlideOnWindowFocus: "off",
        disableFocusListener: false,
      }
    });
  }
}

function dz_rev_slider_5() {
  if (dzQuery("#rev_slider_1175_1").revolution == undefined) {
    revslider_showDoubleJqueryError("#rev_slider_1175_1");
  } else {
    dzrevapi = dzQuery("#rev_slider_1175_1").show().revolution({
      sliderType: "standard",
      jsFileLocation: "plugins/revolution/revolution/js/",
      sliderLayout: "fullwidth",
      dottedOverlay: "none",
      delay: 9000,
      navigation: {
        keyboardNavigation: "off",
        keyboard_direction: "horizontal",
        mouseScrollNavigation: "off",
        mouseScrollReverse: "default",
        onHoverStop: "off",
        touch: {
          touchenabled: "on",
          swipe_threshold: 75,
          swipe_min_touches: 1,
          swipe_direction: "horizontal",
          drag_block_vertical: false
        },
        arrows: {
          style: "uranus",
          enable: true,
          hide_onmobile: true,
          hide_under: 768,
          hide_onleave: false,
          tmp: '',
          left: {
            h_align: "left",
            v_align: "center",
            h_offset: 20,
            v_offset: 0
          },
          right: {
            h_align: "right",
            v_align: "center",
            h_offset: 20,
            v_offset: 0
          }
        }
      },
      responsiveLevels: [1240, 1024, 778, 480],
      visibilityLevels: [1240, 1024, 778, 480],
      gridwidth: [1240, 1024, 778, 480],
      gridheight: [650, 650, 600, 500],
      lazyType: "smart",
      parallax: {
        type: "scroll",
        origo: "slidercenter",
        speed: 400,
        levels: [5, 10, 15, 20, 25, 30, 35, 40, 45, 46, 47, 48, 49, 50, 51, 55],
        type: "scroll",
      },
      shadow: 0,
      spinner: "off",
      stopLoop: "on",
      stopAfterLoops: 0,
      stopAtSlide: 1,
      shuffle: "off",
      autoHeight: "off",
      fullScreenAutoWidth: "off",
      fullScreenAlignForce: "off",
      fullScreenOffsetContainer: "",
      fullScreenOffset: "70",
      disableProgressBar: "on",
      hideThumbsOnMobile: "off",
      hideSliderAtLimit: 0,
      hideCaptionAtLimit: 0,
      hideAllCaptionAtLilmit: 0,
      debugMode: false,
      fallbacks: {
        simplifyAll: "off",
        nextSlideOnWindowFocus: "off",
        disableFocusListener: false,
      }
    });
  }
}

function dz_rev_slider_8() {
  if (dzQuery("#rev_slider_1069_1").revolution == undefined) {
    revslider_showDoubleJqueryError("#rev_slider_1069_1");
  } else {
    dzrevapi = dzQuery("#rev_slider_1069_1").show().revolution({
      sliderType: "hero",
      jsFileLocation: "plugins/revolution/revolution/js/",
      sliderLayout: "fullscreen",
      dottedOverlay: "none",
      delay: 9000,
      navigation: {},
      responsiveLevels: [1240, 1024, 778, 480],
      visibilityLevels: [1240, 1024, 778, 480],
      gridwidth: [1240, 1024, 778, 480],
      gridheight: [868, 768, 960, 720],
      lazyType: "none",
      parallax: {
        type: "scroll",
        origo: "slidercenter",
        speed: 1000,
        levels: [5, 10, 15, 20, 25, 30, 35, 40, 45, 46, 47, 48, 49, 50, 51, 55],
        type: "scroll",
      },
      shadow: 0,
      spinner: "spinner2",
      autoHeight: "off",
      fullScreenAutoWidth: "off",
      fullScreenAlignForce: "off",
      fullScreenOffsetContainer: "",
      fullScreenOffset: "0",
      disableProgressBar: "on",
      hideThumbsOnMobile: "off",
      hideSliderAtLimit: 0,
      hideCaptionAtLimit: 0,
      hideAllCaptionAtLilmit: 0,
      debugMode: false,
      fallbacks: {
        simplifyAll: "off",
        disableFocusListener: false,
      }
    });
  }
}

function dz_rev_slider_11() {
  if (dzQuery("#rev_slider_11_1").revolution == undefined) {
    revslider_showDoubleJqueryError("#rev_slider_11_1");
  } else {
    dzrevapi = dzQuery("#rev_slider_11_1").show().revolution({
      sliderType: "standard",
      sliderLayout: "fullscreen",
      dottedOverlay: "none",
      delay: 9000,
      navigation: {
        onHoverStop: "off",
      },
      responsiveLevels: [1240, 1240, 778, 778],
      visibilityLevels: [1240, 1240, 778, 778],
      gridwidth: [1640, 1640, 778, 778],
      gridheight: [768, 768, 960, 960],
      lazyType: "none",
      parallax: {
        type: "mouse",
        origo: "enterpoint",
        speed: 400,
        speedbg: 0,
        speedls: 0,
        levels: [5, 10, 15, 20, 25, 30, 35, 40, 45, 46, 47, 48, 49, 50, 51, 55],
      },
      shadow: 0,
      spinner: "spinner0",
      stopLoop: "off",
      stopAfterLoops: -1,
      stopAtSlide: -1,
      shuffle: "off",
      autoHeight: "off",
      fullScreenOffsetContainer: ".header",
      disableProgressBar: "on",
      hideThumbsOnMobile: "off",
      hideSliderAtLimit: 0,
      hideCaptionAtLimit: 0,
      hideAllCaptionAtLilmit: 0,
      debugMode: false,
      fallbacks: {
        simplifyAll: "off",
        nextSlideOnWindowFocus: "off",
        disableFocusListener: false,
      }
    });
  }
}

function dz_rev_slider_12() {
  if (dzQuery("#rev_slider_1061_1").revolution == undefined) {
    revslider_showDoubleJqueryError("#rev_slider_1061_1");
  } else {
    dzrevapi = dzQuery("#rev_slider_1061_1").show().revolution({
      sliderType: "standard",
      jsFileLocation: "plugins/revolution/revolution/js/",
      sliderLayout: "fullscreen",
      dottedOverlay: "none",
      delay: 9000,
      navigation: {
        keyboardNavigation: "off",
        keyboard_direction: "horizontal",
        mouseScrollNavigation: "off",
        mouseScrollReverse: "default",
        onHoverStop: "off",
        touch: {
          touchenabled: "on",
          swipe_threshold: 75,
          swipe_min_touches: 50,
          swipe_direction: "horizontal",
          drag_block_vertical: false
        },
        arrows: {
          style: "uranus",
          enable: true,
          hide_onmobile: true,
          hide_under: 778,
          hide_onleave: false,
          tmp: '',
          left: {
            h_align: "left",
            v_align: "center",
            h_offset: 15,
            v_offset: 0
          },
          right: {
            h_align: "right",
            v_align: "center",
            h_offset: 15,
            v_offset: 0
          }
        }
      },
      responsiveLevels: [1240, 1024, 778, 480],
      visibilityLevels: [1240, 1024, 778, 480],
      gridwidth: [1240, 1024, 778, 480],
      gridheight: [868, 768, 960, 720],
      lazyType: "none",
      parallax: {
        type: "3D",
        origo: "slidercenter",
        speed: 1000,
        levels: [2, 4, 6, 8, 10, 12, 14, 16, 45, 50, 47, 48, 49, 50, 0, 50],
        type: "3D",
        ddd_shadow: "off",
        ddd_bgfreeze: "on",
        ddd_overflow: "hidden",
        ddd_layer_overflow: "visible",
        ddd_z_correction: 100,
      },
      spinner: "off",
      stopLoop: "on",
      stopAfterLoops: 0,
      stopAtSlide: 1,
      shuffle: "off",
      autoHeight: "off",
      fullScreenAutoWidth: "off",
      fullScreenAlignForce: "off",
      fullScreenOffsetContainer: "",
      fullScreenOffset: "0",
      disableProgressBar: "on",
      hideThumbsOnMobile: "off",
      hideSliderAtLimit: 0,
      hideCaptionAtLimit: 0,
      hideAllCaptionAtLilmit: 0,
      debugMode: false,
      fallbacks: {
        simplifyAll: "off",
        nextSlideOnWindowFocus: "off",
        disableFocusListener: false,
      }
    });
  }
}

function dz_rev_slider_13() {
  if (dzQuery("#rev_slider_14_1").revolution == undefined) {
    revslider_showDoubleJqueryError("#rev_slider_14_1");
  } else {
    dzrevapi = dzQuery("#rev_slider_14_1").show().revolution({
      sliderType: "hero",
      jsFileLocation: "plugins/revolution/revolution/js/",
      sliderLayout: "fullscreen",
      dottedOverlay: "none",
      delay: 9000,
      particles: {
        startSlide: "first",
        endSlide: "last",
        zIndex: "6",
        particles: {
          number: {
            value: 100
          },
          color: {
            value: "#ffffff"
          },
          shape: {
            type: "circle",
            stroke: {
              width: 0,
              color: "#ffffff",
              opacity: 1
            },
            image: {
              src: ""
            }
          },
          opacity: {
            value: 1,
            random: true,
            min: 0.25,
            anim: {
              enable: false,
              speed: 3,
              opacity_min: 0,
              sync: false
            }
          },
          size: {
            value: 3,
            random: true,
            min: 0.5,
            anim: {
              enable: false,
              speed: 40,
              size_min: 1,
              sync: false
            }
          },
          line_linked: {
            enable: false,
            distance: 150,
            color: "#ffffff",
            opacity: 0.4,
            width: 1
          },
          move: {
            enable: true,
            speed: 1,
            direction: "top",
            random: true,
            min_speed: 1,
            straight: false,
            out_mode: "out"
          }
        },
        interactivity: {
          events: {
            onhover: {
              enable: true,
              mode: "bubble"
            },
            onclick: {
              enable: false,
              mode: "repulse"
            }
          },
          modes: {
            grab: {
              distance: 400,
              line_linked: {
                opacity: 0.5
              }
            },
            bubble: {
              distance: 400,
              size: 0,
              opacity: 0.01
            },
            repulse: {
              distance: 200
            }
          }
        }
      },
      navigation: {},
      responsiveLevels: [1240, 1024, 778, 480],
      visibilityLevels: [1240, 1024, 778, 480],
      gridwidth: [1240, 1024, 778, 480],
      gridheight: [868, 768, 960, 720],
      lazyType: "none",
      parallax: {
        type: "mouse",
        origo: "slidercenter",
        speed: 400,
        levels: [1, 2, 3, 4, 5, 10, 15, 20, 25, 46, 47, 48, 49, 50, 51, 55],
      },
      shadow: 0,
      spinner: "off",
      autoHeight: "off",
      fullScreenAutoWidth: "off",
      fullScreenAlignForce: "off",
      fullScreenOffsetContainer: ".header",
      fullScreenOffset: "",
      disableProgressBar: "on",
      hideThumbsOnMobile: "off",
      hideSliderAtLimit: 0,
      hideCaptionAtLimit: 0,
      hideAllCaptionAtLilmit: 0,
      debugMode: false,
      fallbacks: {
        simplifyAll: "off",
        disableFocusListener: false,
      }
    });
  }
}

function dz_rev_slider_14() {
  if (dzQuery("#welcome").revolution == undefined) {
    revslider_showDoubleJqueryError("#welcome");
  } else {
    dzrevapi = dzQuery("#welcome").show().revolution({
      sliderType: "standard",
      jsFileLocation: "plugins/revolution/revolution/js/",
      sliderLayout: "fullscreen",
      dottedOverlay: "none",
      delay: 9000,
      particles: {
        startSlide: "first",
        endSlide: "last",
        zIndex: "1",
        particles: {
          number: {
            value: 25
          },
          color: {
            value: "#ffffff"
          },
          shape: {
            type: "circle",
            stroke: {
              width: 0,
              color: "#ffffff",
              opacity: 1
            },
            image: {
              src: ""
            }
          },
          opacity: {
            value: 1,
            random: true,
            min: 0.25,
            anim: {
              enable: true,
              speed: 3,
              opacity_min: 0,
              sync: false
            }
          },
          size: {
            value: 2,
            random: true,
            min: 0.5,
            anim: {
              enable: true,
              speed: 5,
              size_min: 1,
              sync: false
            }
          },
          line_linked: {
            enable: false,
            distance: 150,
            color: "#ffffff",
            opacity: 0.4,
            width: 1
          },
          move: {
            enable: true,
            speed: 2,
            direction: "none",
            random: true,
            min_speed: 1,
            straight: false,
            out_mode: "out"
          }
        },
        interactivity: {
          events: {
            onhover: {
              enable: false,
              mode: "bubble"
            },
            onclick: {
              enable: false,
              mode: "repulse"
            }
          },
          modes: {
            grab: {
              distance: 400,
              line_linked: {
                opacity: 0.5
              }
            },
            bubble: {
              distance: 400,
              size: 40,
              opacity: 0.5
            },
            repulse: {
              distance: 200
            }
          }
        }
      },
      revealer: {
        direction: "open_horizontal",
        color: "#ffffff",
        duration: "1500",
        delay: "0",
        easing: "Power2.easeInOut",
        overlay_enabled: true,
        overlay_color: "#000000",
        overlay_duration: "1500",
        overlay_delay: "0",
        overlay_easing: "Power2.easeInOut",
        spinner: "1",
        spinnerColor: "#006dd2",
      },
      navigation: {
        keyboardNavigation: "off",
        keyboard_direction: "horizontal",
        mouseScrollNavigation: "off",
        mouseScrollReverse: "default",
        onHoverStop: "off",
        arrows: {
          style: "uranus",
          enable: true,
          hide_onmobile: false,
          hide_onleave: false,
          tmp: '',
          left: {
            h_align: "left",
            v_align: "center",
            h_offset: 10,
            v_offset: 0
          },
          right: {
            h_align: "right",
            v_align: "center",
            h_offset: 10,
            v_offset: 0
          }
        }
      },
      viewPort: {
        enable: true,
        outof: "wait",
        visible_area: "80%",
        presize: true
      },
      responsiveLevels: [1240, 1025, 778, 480],
      visibilityLevels: [1240, 1025, 778, 480],
      gridwidth: [1240, 1025, 778, 480],
      gridheight: [970, 970, 960, 720],
      lazyType: "single",
      parallax: {
        type: "scroll",
        origo: "slidercenter",
        speed: 400,
        speedbg: 0,
        speedls: 0,
        levels: [5, 10, 15, 20, 25, 30, 35, 40, 45, 46, 47, 48, 49, 50, 51, 55],
      },
      shadow: 0,
      spinner: "spinner2",
      stopLoop: "on",
      stopAfterLoops: 0,
      stopAtSlide: 1,
      shuffle: "off",
      autoHeight: "off",
      fullScreenAutoWidth: "off",
      fullScreenAlignForce: "off",
      fullScreenOffsetContainer: "",
      fullScreenOffset: "",
      disableProgressBar: "on",
      hideThumbsOnMobile: "off",
      hideSliderAtLimit: 0,
      hideCaptionAtLimit: 0,
      hideAllCaptionAtLilmit: 0,
      debugMode: false,
      fallbacks: {
        simplifyAll: "off",
        nextSlideOnWindowFocus: "off",
        disableFocusListener: false,
      }
    });

  }
}

function dz_rev_slider_15() {
  if (dzQuery("#rev_slider_11_1").revolution == undefined) {
    revslider_showDoubleJqueryError("#rev_slider_11_1");
  } else {
    dzrevapi = dzQuery("#rev_slider_11_1").show().revolution({
      sliderType: "standard",
      sliderLayout: "fullscreen",
      dottedOverlay: "none",
      delay: 9000,
      navigation: {
        onHoverStop: "off",
      },
      responsiveLevels: [1240, 1240, 778, 778],
      visibilityLevels: [1240, 1240, 778, 778],
      gridwidth: [1640, 1640, 778, 778],
      gridheight: [768, 768, 960, 960],
      lazyType: "none",
      parallax: {
        type: "mouse",
        origo: "enterpoint",
        speed: 400,
        speedbg: 0,
        speedls: 0,
        levels: [5, 10, 15, 20, 25, 30, 35, 40, 45, 46, 47, 48, 49, 50, 51, 55],
      },
      shadow: 0,
      spinner: "spinner0",
      stopLoop: "off",
      stopAfterLoops: -1,
      stopAtSlide: -1,
      shuffle: "off",
      autoHeight: "off",
      fullScreenOffsetContainer: "",
      disableProgressBar: "on",
      hideThumbsOnMobile: "off",
      hideSliderAtLimit: 0,
      hideCaptionAtLimit: 0,
      hideAllCaptionAtLilmit: 0,
      debugMode: false,
      fallbacks: {
        simplifyAll: "off",
        nextSlideOnWindowFocus: "off",
        disableFocusListener: false,
      }
    });
  }
}

$(document).ready(function() {
  'use strict';
  dz_rev_slider_1();
  $('.lazy').Lazy();
}); /*ready*/


$(window).on('load', function() {

  var sync1 = $('#sync1');
  var sync2 = $('#sync2');
  var slidesPerPage = 4; //globaly define number of elements per page
  var syncedSecondary = true;

  sync1.owlCarousel({
    items: 1,
    nav: true,
    autoplaySpeed: 3000,
    autoplay: false,
    dots: false,
    loop: false,
    responsiveRefreshRate: 200,
    navText: ['<i class="la la-angle-left"></i>', '<i class="la la-angle-right"></i>'],
    responsive: {
      0: {
        nav: false
      },
      768: {
        nav: true
      }
    }
  }).on('changed.owl.carousel', syncPosition);

  sync2.on('initialized.owl.carousel', function() {
    sync2.find('.owl-item').eq(0).addClass('current');
  }).owlCarousel({
    items: slidesPerPage,
    dots: false,
    nav: false,
    margin: 20,
    autoplaySpeed: 3000,
    autoplay: false,
    slideBy: slidesPerPage, //alternatively you can slide by 1, this way the active slide will stick to the first item in the second carousel
    responsiveRefreshRate: 100,
    responsive: {
      0: {
        items: 2
      },
      480: {
        items: 2
      },
      768: {
        items: 3
      },
      1024: {
        items: 4
      },
      1400: {
        items: 4
      }
    }
  }).on('changed.owl.carousel', syncPosition2);

  function syncPosition(el) {
    //if you set loop to false, you have to restore this next line
    var current = el.item.index;

    //if you disable loop you have to comment this block
    /*var count = el.item.count - 1;
    var current = Math.round(el.item.index - (el.item.count / 2) - .5);

    if (current < 0) {
        current = count;
    }
    if (current > count) {
        current = 0;
    }*/

    //end block

    // console.log(current);

    sync2
      .find('.owl-item')
      .removeClass('current')
      .eq(current)
      .addClass('current');
    var onscreen = sync2.find('.owl-item.active').length - 1;
    var start = sync2.find('.owl-item.active').first().index();
    var end = sync2.find('.owl-item.active').last().index();

    /*if (current > end) {
        sync2.data('owl.carousel').to(current, 100, true);
    }
    if (current < start) {
        sync2.data('owl.carousel').to(current - onscreen, 100, true);
    }*/
  }

  function syncPosition2(el) {
    if (syncedSecondary) {
      var number = el.item.index;
      sync1.data('owl.carousel').to(number, 100, true);
    }
  }

  sync2.on('click', '.owl-item', function(e) {
    e.preventDefault();
    var number = $(this).index();
    //sync1.data('owl.carousel').to(number, 300, true);

    sync1.data('owl.carousel').to(number, 300, true);

  });
});
